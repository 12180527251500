.marketing-page {

  .card {
    padding: 24px;
  }

  /******* Accordeon ******/
  .marketing-page-accordion-container {
    margin: 0 auto;

    @media(min-width: 768px) {
      width: 80%;
    }

    .accordion-item {
      background: #F8F9FA;
      border: 0;
      border-radius: 4px;

      .accordion-button {
        box-shadow: none;
        background: #F8F9FA;
        color: #495057;
      }

      .accordion-header {
        .accordion-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #495057;
        }

        .accordion-description {
          font-size: 14px;
          line-height: 18px;
          padding-right: 5px;
        }
      }

      .accordion-body {
        .step-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #000000;
        }

        .step-description {
          font-size: 16px;
          font-weight: 400;
          text-align: justify;
          line-height: 26px;
          color: #74788D;
        }
      }

      iframe {
        margin: 0 auto 20px;
        display: flex;

        @media(max-width: 1240px) {
          width: 100% !important;
        }
      }
    }
  }
}

/******* Banner ******/
.marketing-page-banner {
  overflow: hidden;
  border-radius: 4px;
  width: 100%;

  picture,
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}